import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganisationsAction } from "redux/actions/fetchOrganisationsAction";
import Loading from "shared/components/Loading";
import { useTranslation } from "react-i18next";
import OrganisationItem from "../../components/OrganisationItem";
import { sortByOrderType } from "helpers/sortByOrderType";
import ListHeader from "components/ListHeader";
import FilterOrganisations from "components/FilterOrganisations";
import SelectedFilters from "components/FilterOrganisations/SelectedFilters";
import Button from "shared/components/buttons/Button";
import { ICONS } from "shared/components/Icon";
import { moduleKinds, moduleRequestStatuses } from "constants/module";

import "./style.scss";
import "./statistics.scss";

export default function Organisations() {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const [active, setActive] = useState(false);
    const [sortIndex, setSortIndex] = useState(0);
    const [sortDirection, setSortDirection] = useState(false);

    useEffect(() => {
        dispatch(fetchOrganisationsAction());
    }, [dispatch]);

    const { organisations, loading } = useSelector(state => state.organisation);

    const hasOrganisations = () => {
        return Array.isArray(organisations) && organisations.length > 0;
    };

    const HEADERS = [
        { field: "label", label: t("organisation.singular") },
        { field: "owner", label: t("organisation.owner.singular") },
        { field: "modules", label: t("organisation.module.plural") },
        { field: "numberOfBranches", label: t("organisation.branches.plural") },
        { field: "numberOfUsers", label: t("organisation.user.plural") }
    ];

    const sortItems = (items) => {
        return Array.isArray(items) && items.length > 0 && sortIndex >= 0
            ? sortByOrderType(HEADERS, items, sortIndex, sortDirection)
            : items;
    };

    const onSort = (idx) => {
        setSortDirection(!sortDirection);
        setSortIndex(idx);
    };

    const renderEmptyState = () => {
        return (
            <div className="container-empty">
                {t("admin.organisations.empty")}
            </div>
        );
    };

    const renderOrganisationItems = () => {
        const sortedItems = sortItems(organisations);
        return sortedItems.map((item, index) => {
            return (
                <OrganisationItem
                    key={index}
                    index={index}
                    item={item}
                />
            );
        });
    };

    const renderOrganisations = () => {
        if (hasOrganisations()) {
            return renderOrganisationItems();
        } else if (!loading) {
            return renderEmptyState();
        }
    };

    const renderButtons = () => {
        return  (
            <div className="organisation-overview-buttons">
                <Button
                    label={t("filter.singular")}
                    onClick={() => setActive(!active)}
                    iconRight={ICONS.FILTER}
                    outlined />
            </div>
        );
    };


    const renderListHeader = () => {
        return Array.isArray(organisations) && organisations.length > 0 && (
            <ListHeader onSort={onSort}
                items={HEADERS}
                sortIndex={sortIndex}
                sortDirection={sortDirection}
            />);
    };

    const nrOfOrganisations = () => {
        if (Array.isArray(organisations)) {
            return organisations.length;
        }
        return 0;

    };

    const nrOkComplyModules = () => {
        let nrOkComplyModules = 0;
        if (Array.isArray(organisations)) {
            organisations.forEach(organisation => {
                const { modules } = organisation;
                if (typeof modules[moduleKinds.OkComply] !== "undefined" &&
                    modules[moduleKinds.OkComply] === moduleRequestStatuses.ACTIVE) {
                    nrOkComplyModules++;
                }
            });
        }
        return nrOkComplyModules;
    };

    const nrOkAuditModules = () => {
        let nrOkAuditModules = 0;
        if (Array.isArray(organisations)) {
            organisations.forEach(organisation => {
                const { modules } = organisation;
                if (typeof modules[moduleKinds.OkAudit] !== "undefined" &&
                    modules[moduleKinds.OkAudit] === moduleRequestStatuses.ACTIVE) {
                    nrOkAuditModules++;
                }
            });
        }
        return nrOkAuditModules;
    };

    const nrOkPublishModules = () => {
        let nrOkPublishModules = 0;
        if (Array.isArray(organisations)) {
            organisations.forEach(organisation => {
                const { modules } = organisation;
                if (typeof modules[moduleKinds.OkPublish] !== "undefined" &&
                    modules[moduleKinds.OkPublish] === moduleRequestStatuses.ACTIVE) {
                    nrOkPublishModules++;
                }
            });
        }
        return nrOkPublishModules;
    };

    const nrOfBranches = () => {
        let total = 0;
        if (Array.isArray(organisations))  {
            organisations.forEach(organisation => {
                if (typeof organisation?.numberOfBranches === "number") {
                    total += organisation.numberOfBranches;
                }
            });
        }
        return total;
    };

    const nrOfUsers = () => {
        let total = 0;
        if (Array.isArray(organisations))  {
            organisations.forEach(organisation => {
                if (typeof organisation?.numberOfUsers === "number") {
                    total += organisation.numberOfUsers;
                }
            });
        }
        return total;
    };

    const renderStatistics = () => {
        return hasOrganisations() && (
            <div className="statistics-row">
                <div className="statistics-title">
                    {t("organisation.total.label")}
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.plural")}
                    </div>
                    <div className="item">
                        {nrOfOrganisations()}
                    </div>
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.module.okcomply.plural")}
                    </div>
                    <div className="item">
                        {nrOkComplyModules()}
                    </div>
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.module.audit.plural")}
                    </div>
                    <div className="item">
                        {nrOkAuditModules()}
                    </div>
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.module.publish.plural")}
                    </div>
                    <div className="item">
                        {nrOkPublishModules()}
                    </div>
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.branch.plural")}
                    </div>
                    <div className="item">
                        {nrOfBranches()}
                    </div>
                </div>
                <div className="statistics-column">
                    <div className="label">
                        {t("organisation.user.plural")}
                    </div>
                    <div className="item">
                        {nrOfUsers()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="organisation-overview">
            {!active &&
                <div className="overview-header">
                    <h2>{t("admin.organisations.title")}</h2>
                    {renderButtons()}
                </div>}
            {active  ?
                <FilterOrganisations onClose={() => setActive(false)} /> :
                <SelectedFilters />
            }
            <div className="spacer" />
            <div className="filter-divider" />
            <div className="spacer-double" />

            {loading ?
                <Loading /> :
                <>
                    {renderStatistics()}
                    {renderListHeader()}
                    <div className="organisation-list-items">
                        {renderOrganisations()}
                    </div>
                </>
            }
        </div>
    );

}