import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon, { ICONS } from "shared/components/Icon";
import "./style.scss";

export default function ListHeaderItem({
    headerItem,
    onClick,
    index,
    className,
    sort,
    sortDirection
}) {
    const { label } = headerItem;
    const setClasses = classnames(className, "noselect",
        sort && "sort",
        index === 6 && "thin"
    );

    const renderIcon = () => {
        const sortDirectionIcon = (sort && sortDirection) ?
            <Icon name={ICONS.ANGLE_UP} /> :
            <Icon name={ICONS.ANGLE_DOWN} />;
        return <span>{sortDirectionIcon}</span>;
    };

    return (
        <div className={setClasses} onClick={() => onClick && onClick(index)}>
            <span className="noselect has-pointer">
                {label}
            </span>
            {sort && renderIcon()}
        </div>);
}

ListHeaderItem.propTypes = {
    headerItem: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    className: PropTypes.string,
    sort: PropTypes.bool,
    sortDirection: PropTypes.bool
};
