import produce from "immer";

import {
    ORGANISATION_SET_ORGANISATIONS,
    ORGANISATION_SET_LOADING,
    ORGANISATION_SET_FILTERS,
    ORGANISATION_FILTER_REMOVE,
    ORGANISATION_SET_ORGANISATION,
    ORGANISATION_REMOVE_ORGANISATION,
    ORGANISATION_UPDATE_ORGANISATION
} from "../types";

const initialState = {
    organisations: [],
    loading: false,
    filters: {},
    organisation: null
};

const organisationReducer = produce((draft, action) => {
    switch (action.type) {
        case ORGANISATION_REMOVE_ORGANISATION: {
            const organisations = draft.organisations;
            // filter out removed organisation
            if (Array.isArray(organisations)) {
                draft.organisations = organisations
                    .filter(({ id }) => id !== action.organisationId);
            }
            break;
        }
        case ORGANISATION_SET_ORGANISATIONS:
            draft.organisations = action.organisations;
            break;
        case ORGANISATION_SET_LOADING:
            draft.loading = action.loading;
            break;
        case ORGANISATION_SET_FILTERS: {
            draft.filters = action.filters;
            break;
        }
        case ORGANISATION_FILTER_REMOVE: {
            draft.filters[action.filterName] = null;
            break;
        }
        case ORGANISATION_SET_ORGANISATION: {
            draft.organisation = action.organisation;
            break;
        }
        case ORGANISATION_UPDATE_ORGANISATION: {
            const index = draft.organisations.findIndex(
                o => o.id === action.organisation.id);
            if (index > -1) {
                draft.organisations[index] = action.organisation;
            }
            break;
        }
        default:
            break;
    }
}, initialState);

export default organisationReducer;