import client from "../../graphql/client";
import { loader } from "graphql.macro";
import { organisationSetLoading, organisationSetOrganisations } from "../../redux/actions/index";
import fetchGraphQLErrors from "helpers/fetchGraphqlErrors";
import { snackbarError } from "shared/redux/actions/Snackbar";

const fetchOrganisationsQuery = loader("../../graphql/queries/adminOrganisations.graphql");

export function fetchOrganisationsAction() {
    return async (dispatch, getState) => {
        dispatch(organisationSetLoading(true));
        dispatch(organisationSetOrganisations([]));
        const { organisation } = getState();
        const { filters } = organisation;
        const label = typeof filters?.label === "string" ? filters.label: undefined;
        const module = typeof filters?.modules === "string" ? filters.modules: undefined;
        client.query({ query: fetchOrganisationsQuery, variables: { label, module },
            fetchPolicy: "network-only" })
            .then(response => {
                const { data } = response;
                const { adminOrganisations } = data;
                dispatch(organisationSetOrganisations(adminOrganisations));
                dispatch(organisationSetLoading(false));
            }).catch(error => {
                const errorMessage = fetchGraphQLErrors(error);
                console.log("error", error);
                dispatch(snackbarError(errorMessage));
                dispatch(organisationSetLoading(true));
            });
    };
}
