// organisation reducer
export const ORGANISATION_SET_ORGANISATIONS = "ORGANISATION_SET_ORGANISATIONS";
export const ORGANISATION_SET_LOADING = "ORGANISATION_SET_LOADING";
export const ORGANISATION_SET_FILTERS = "ORGANISATIONS_SET_FILTERS";
export const ORGANISATION_FILTER_REMOVE = "ORGANISATION_FILTER_REMOVE";
export const ORGANISATION_SET_ORGANISATION = "ORGANISATION_SET_ORGANISATION";
export const ORGANISATION_REMOVE_ORGANISATION = "ORGANISATION_REMOVE_ORGANISATION";
export const ORGANISATION_UPDATE_ORGANISATION = "ORGANISATION_UPDATE_ORGANISATION";

// user reducer
export const USER_LOAD_USER_PROFILE = "USER_LOAD_USER_PROFILE";
