import {
    USER_LOAD_USER_PROFILE,
    ORGANISATION_SET_ORGANISATIONS,
    ORGANISATION_SET_LOADING,
    ORGANISATION_SET_FILTERS,
    ORGANISATION_SET_ORGANISATION,
    ORGANISATION_REMOVE_ORGANISATION,
    ORGANISATION_UPDATE_ORGANISATION
} from "../types";

export function setUserProfile(userProfile) {
    return {
        type: USER_LOAD_USER_PROFILE,
        userProfile
    };
}

export function organisationSetOrganisations(organisations) {
    return {
        type: ORGANISATION_SET_ORGANISATIONS,
        organisations
    };
}

export function organisationSetLoading(loading) {
    return {
        type: ORGANISATION_SET_LOADING,
        loading
    };
}

export function organisationSetFilters(filters) {
    return {
        type: ORGANISATION_SET_FILTERS,
        filters
    };
}

export function setOrganisation(organisation) {
    return {
        type: ORGANISATION_SET_ORGANISATION,
        organisation
    };
}

export function removeOrganisation(organisationId) {
    return {
        type: ORGANISATION_REMOVE_ORGANISATION,
        organisationId
    };
}

export function organisationUpdateOrganisation(organisation) {
    return {
        type: ORGANISATION_UPDATE_ORGANISATION,
        organisation
    };
}