import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Tab = ({ tabName, basePath }) => {

    const [t] = useTranslation();
    const { tab } = useParams();

    const className = classnames(
        "okcomply-tab",
        tab === tabName && "is-active"
    );

    return (
        <Link className={className} to={`${basePath}/${tabName}`}>
            {t(`${tabName}.label`)}
        </Link>
    );

};

Tab.propTypes = {
    tabName: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired
};

export default Tab;
