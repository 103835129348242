/**
 *
 * @param error
 * @returns {string}
 */

function fetchGraphQLErrors(error) {
    if(error.graphQLErrors !== undefined &&
        error.graphQLErrors[0] !== undefined &&
        typeof error.graphQLErrors[0].message === "string") {
        return error.graphQLErrors[0].message;
    }
    return "unknown.error.occurred";
}

export default fetchGraphQLErrors;