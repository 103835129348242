import client from "../../graphql/client";
import { loader } from "graphql.macro";

const fetchOrganisationQuery = loader("../../graphql/queries/adminOrganisation.graphql");

export function fetchOrganisationAction(organisationId) {
    return async () => {
        return client.query({
            query: fetchOrganisationQuery,
            variables: { organisationId },
            fetchPolicy: "network-only"
        });
    };
}
