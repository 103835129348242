import React from "react";
import { useSelector } from "react-redux";

const filterFields = {
    LABEL: "label",
    MODULES: "modules"
};

const SelectedFilters = () => {

    const { filters, organisations } = useSelector(state => state.organisation);

    const renderFilter = (value, type, index) => {

        if (value) {
            let renderValue = value;
            switch (type) {
                case filterFields.LABEL: {
                    if (organisations && organisations[value]) {
                        renderValue = organisations[value].label;
                    }
                    break;
                }
                case filterFields.MODULES: {
                    if (organisations &&
                        typeof organisations?.modules !== "undefined" &&
                        organisations?.modules[value] !== "undefined") {
                        renderValue = organisations[value].label;
                    }
                    break;
                }
                default:
                    break;
            }

            return (
                <div key={index} className="filter-item">
                    <span>{renderValue}</span>
                </div>
            );
        }

        return null;
    };

    const filterKeys = filters ? Object.keys(filters) : [];

    return (
        <div className="selected-filter">
            {filterKeys.map((key, index) => {
                return renderFilter(filters[key], key, index);
            })}
        </div>
    );
};

export default SelectedFilters;