import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import Icon from "shared/components/Icon";
import { MENU_ITEMS } from "constants/menu";
import { ROLE_LEVELS } from "constants/roles";
import Modules from "shared/components/Modules";

import "./style.scss";

export default function Navigation() {

    const [t] = useTranslation();

    const { user } = useSelector(state => state.user);

    const { role: userRole } = user?.membership?.okAudit || {};

    const menuItems = () => {
        return MENU_ITEMS.map((menuItem, index) => {
            const { label, path, iconName, className, role } = menuItem;
            if (role && ROLE_LEVELS[userRole] < ROLE_LEVELS[role]) {
                return null;
            }
            const classes = classnames("menu-item", className ? className : "");
            return (
                <NavLink
                    key={`navigation-${index}`}
                    className={classes}
                    to={path}
                    activeClassName="is-active">
                    <Icon name={iconName} spacedRight />
                    <span className="has-text-white">
                        {t(label)}
                    </span>
                </NavLink>
            );
        });
    };
    return (
        <nav className="navigation" role="navigation">
            <div className="main-navigation">
                <div className="navigation-left">
                    <Link className="navigation-logo" to="/">
                        <img
                            alt="OKcomply Admin"
                            src={require("../../assets/img/logo.png")}
                        />
                        <span>ADMIN</span>
                    </Link>
                    <div className="menu-items">
                        {menuItems()}
                    </div>
                </div>
                <div className="navigation-right">
                    <Modules />
                </div>

            </div>
        </nav>
    );
}

Navigation.propTypes = {
    userRoleLevel: PropTypes.object
};

