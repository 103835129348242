import { organisationSetFilters } from "redux/actions/index";
import { fetchOrganisationsAction } from "redux/actions/fetchOrganisationsAction";

export default function organisationsFilter(filters) {
    return async (dispatch, getState) => {
        const { organisation } = getState();
        const { filters: oldFilters } = organisation;
        if (JSON.stringify(oldFilters) !== JSON.stringify(filters)) {
            dispatch(organisationSetFilters(filters));
            dispatch(fetchOrganisationsAction());
        }
    };
}