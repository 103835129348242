import client from "../../graphql/client";
import { loader } from "graphql.macro";
const removeFromOrganisationMutation  = loader("../../graphql/mutations/removeFromOrganisation.graphql");

export default function removeFromOrganisationAction(organisationId) {
    return () => {
        return client.mutate({
            mutation: removeFromOrganisationMutation,
            variables: { organisationId }
        });
    };
}
