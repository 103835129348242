import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import { store } from "redux/setup/store";
import OkSnackbar from "shared/components/OkSnackbar";
import "moment/locale/nl.js";
import "./App.scss";

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
            <OkSnackbar />
        </Provider>
    );
}
