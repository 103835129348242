import React, { useEffect } from "react";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganisationAction } from "redux/actions/fetchOrganisationAction";
import fetchGraphQLErrors from "helpers/fetchGraphqlErrors";
import { snackbarError } from "shared/redux/actions/Snackbar";
import { isValidIdentifier } from "helpers/isValidIdentifier";
import { useParams } from "react-router-dom";
import TabInfo from "containers/OrganisationDetails/tabs/TabInfo";
import TabEdit from "containers/OrganisationDetails/tabs/TabEdit";
import { setOrganisation } from "redux/actions";

import "./style.scss";

export default function OrganisationDetail() {

    const [t] = useTranslation();

    const history = useHistory();

    const dispatch = useDispatch();

    const { organisation } = useSelector(state => state.organisation);

    const params = useParams();

    const { organisationId } = params;

    useEffect(() => {
        if (isValidIdentifier(organisationId)) {
            dispatch(fetchOrganisationAction(organisationId))
                .then(response => {
                    const { data } = response;
                    const { adminOrganisation } = data;
                    dispatch(setOrganisation(adminOrganisation));
                }).catch(error => {
                    const errorMessage = fetchGraphQLErrors(error);
                    dispatch(snackbarError(errorMessage));
                });
        }
    }, [organisationId, dispatch]);

    const onClose = () => history.push("/organisations");

    const tabs = [
        {
            tabName: "info",
            component: <TabInfo organisation={organisation}  key="info" />
        },
        {
            tabName: "edit",
            component: <TabEdit organisation={organisation}  onClose={onClose} />
        }
    ];

    return organisation && (
        <div className="organisation-detail">
            <Modal
                title={organisation ? `${organisation.label}` : t("not.found")}
                basePath={`/organisations/organisation/${organisation.id}`}
                tabs={tabs}
                onClose={onClose}
            />
        </div>
    );
}