import client from "../../graphql/client";
import { loader } from "graphql.macro";
import { setUserProfile } from "../actions/index";

const fetchUserProfile = loader("shared/graphql/queries/users/fetchUserProfile.graphql");

export function fetchUserProfileAction() {
    return async (dispatch) => {

        const response = await client.query({ query: fetchUserProfile });
        const { data } = response;
        const { userProfile } = data;

        dispatch(setUserProfile(userProfile));
    };
}
