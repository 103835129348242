import client from "../../graphql/client";
import { loader } from "graphql.macro";
const setModuleRequestStatusMutation  = loader(
    "../../graphql/mutations/setModuleRequestStatus.graphql");

export default function setModuleRequestStatusAction(organisationId, module, status) {
    return () => {
        return client.mutate({
            mutation: setModuleRequestStatusMutation,
            variables: { organisationId, module, status }
        });
    };
}
