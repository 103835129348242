import client from "../../graphql/client";
import { loader } from "graphql.macro";
const setBillingMutation  = loader("../../graphql/mutations/setBilling.graphql");

export default function setBillingAction(organisationId, billing) {
    return () => {
        return client.mutate({
            mutation: setBillingMutation,
            variables: { organisationId, billing }
        });
    };
}
