import { compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "user",
        "audit",
        "organisation",
        "review"
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    {}, // initial state
    compose(
        applyMiddleware(thunk),
        /* eslint-disable-next-line */
        (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined") ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    )
);

const persistor = persistStore(store);

export { store, persistor };
