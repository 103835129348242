import produce from "immer";

import {
    USER_LOAD_USER_PROFILE
} from "../types";

const initialState = {
    userProfile: {},
    loading: false,
};

const userReducer = produce((draft, action) => {
    switch (action.type) {
        case USER_LOAD_USER_PROFILE:
            draft.userProfile = action.userProfile;
            draft.loading = false;
            break;
        default:
            break;
    }
}, initialState);

export default userReducer;