import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import InputText from "shared/components/inputs/InputText";
import InputDate from "shared/components/inputs/InputDate";
import Switch from "shared/components/Switch";
import Button from "shared/components/buttons/Button";
import { FormControlLabel } from "@material-ui/core";
import { moduleRequestStatuses } from "constants/module";
import InputSelect from "shared/components/inputs/InputSelect";
import { isValidIdentifier } from "helpers/isValidIdentifier";
import formatUsername from "../../../helpers/formatUsername";
import fetchUsersAction from "redux/actions/fetchUsersAction";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { useDispatch } from "react-redux";
import fetchGraphQLErrors from "helpers/fetchGraphqlErrors";
import setBillingAction from "redux/actions/setBillingAction";
import setModuleRequestStatusAction from "redux/actions/setModuleRequestStatusAction";
import { moduleKinds } from "constants/module";
import { fetchOrganisationAction } from "redux/actions/fetchOrganisationAction";
import { removeOrganisation, setOrganisation } from "redux/actions";
import OkWarning from "shared/components/OkWarning";
import removeOrganisationAction from "redux/actions/removeOrganisationAction";
import { calculateRemovalDate } from "helpers/billing";
import { ICONS } from "shared/components/Icon";
import { useForm, Controller } from "react-hook-form";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { checkDate } from "helpers/checkDate";
import { getInvoiceTo, getModules, getMonthlyAmount, getDefaultValues } from "helpers/formatOrganisation";
import { modules, billingFields } from "constants/billing";
import { message } from "constants/validation";


export default function TabEdit({ organisation, onClose }) {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const module = getModules(organisation);
    const monthlyAmount = getMonthlyAmount(organisation?.billing);
    const defaultInvoiceTo = getInvoiceTo(organisation?.billing);
    const defaultValues = getDefaultValues(organisation);

    const [moduleOkComply, setModuleOkComply] = useState(module.moduleOkComply);
    const [moduleOkAudit, setModuleOkAudit] = useState(module.moduleOkAudit);
    const [moduleOkPublish, setModuleOkPublish] = useState(module.moduleOkPublish);
    const [invoiceTo, setInvoiceTo] = useState(defaultInvoiceTo);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [users, setUsers] = useState(null);
    const [monthlyAmountOkComply] = useState(monthlyAmount.monthlyAmountOkComply);
    const [monthlyAmountOkPublish] = useState(monthlyAmount.monthlyAmountOkPublish);
    const [monthlyAmountOkAudit] = useState(monthlyAmount.monthlyAmountOkAudit);

    const {
        register,
        handleSubmit,
        errors,
        getValues,
        control,
        setValue
    } = useForm({ defaultValues });

    useEffect(() => {
        register(billingFields.MONTHLY_AMOUNT_OK_AUDIT,
            monthlyAmount.monthlyAmountOkAudit);
        register(billingFields.MONTHLY_AMOUNT_OK_PUBLISH,
            monthlyAmount.monthlyAmountOkPublish);
        register(billingFields.MONTHLY_AMOUNT_OK_COMPLY,
            monthlyAmount.monthlyAmountOkComply);

    }, [
        monthlyAmount,
        register]);


    useEffect(() => {
        async function fetchUsers(organisationId) {
            await dispatch(fetchUsersAction(organisationId))
                .then(result => {
                    const { data } = result;
                    const { users } = data;
                    setUsers(users);
                }).catch((error) => {
                    const errorMessage = fetchGraphQLErrors(error);
                    dispatch(snackbarError(errorMessage));
                });
        }

        if (isValidIdentifier(organisation.id)) {
            fetchUsers(organisation.id);
        }
    }, [organisation, dispatch]);


    const onConfirm = () => {
        if (isValidIdentifier(organisation.id)) {
            dispatch(removeOrganisationAction(organisation.id))
                .then(result => {
                    const { data } = result;
                    dispatch(removeOrganisation(data.removeOrganisation));
                    setShowWarningMessage(false);
                    onClose();
                }).catch(error => {
                    const errorMessage = fetchGraphQLErrors(error);
                    dispatch(snackbarError(t(errorMessage)));
                });
        }
    };

    const onClick_Remove = () => {
        const endDate = getValues(billingFields.END_DATE);
        if (calculateRemovalDate(endDate)) {
            setShowWarningMessage(true);
        }
    };

    const onCancel = () => {
        setShowWarningMessage(false);
    };

    const onChangeInvoiceTo = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setInvoiceTo(event.target.value);
    };

    const onSubmit = async (formData) => {

        const {
            invoiceTo,
            agreements,
            monthlyAmountOkPublish,
            monthlyAmountOkComply,
            monthlyAmountOkAudit
        } = formData;

        let startDate;
        let endDate;

        function convertDateString(date) {
            const dateArr = date.split("/");
            return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
        }

        if (checkDate(formData.startDate)) {
            startDate = convertDateString(formData.startDate);
        }

        if (checkDate(formData.endDate)) {
            endDate = convertDateString(formData.endDate);
        }

        if (startDate === "") {
            dispatch(snackbarError(message.invalidStartDate));
            return;
        }

        if (!moment(startDate, "YYYY-MM-DD", true).isValid()) {
            dispatch(snackbarError(message.invalidStartDate));
            return;
        }

        if (endDate) {
            if (!moment(endDate, "YYYY-MM-DD", true).isValid()) {
                dispatch(snackbarError(message.invalidEndDate));
                return;
            }
        }

        if (typeof invoiceTo === "undefined" || !isValidIdentifier(invoiceTo)) {
            dispatch(snackbarError(message.invalidInvoiceTo));
            return;
        }

        const temp1 = parseFloat(monthlyAmountOkComply);
        const temp2 = parseFloat(monthlyAmountOkAudit);
        const temp3 = parseFloat(monthlyAmountOkPublish);

        if (moduleOkComply) {
            if (Number.isNaN(temp1) || temp1 < 0) {
                dispatch(snackbarError(t(message.invalidMonthlyAmountOkComply)));
                return;
            }
        }

        if (moduleOkAudit) {
            if (Number.isNaN(temp2) || temp2 < 0) {
                dispatch(snackbarError(t(message.invalidMonthlyAmountOkAudit)));
                return;
            }
        }

        if (moduleOkPublish) {
            if (Number.isNaN(temp3) || temp3 < 0) {
                dispatch(snackbarError(t(message.invalidMonthlyAmountOkPublish)));
                return;
            }
        }

        const monthlyAmount = {};
        monthlyAmount[moduleKinds.OkComply] = temp1;
        monthlyAmount[moduleKinds.OkAudit] = temp2;
        monthlyAmount[moduleKinds.OkPublish] = temp3;

        const billing = {
            startDate,
            endDate: endDate ? endDate: undefined,
            monthlyAmount,
            agreements,
            invoiceTo
        };

        if (isValidIdentifier(organisation.id)) {

            try {

                let isError = false;

                await dispatch(setBillingAction(organisation.id, billing))
                    .catch(error => {
                        isError = true;
                        const errorMessage = fetchGraphQLErrors(error);
                        dispatch(snackbarError(t(errorMessage)));
                    });

                await dispatch(setModuleRequestStatusAction(organisation.id,
                    moduleKinds.OkComply, moduleOkComply ?
                        moduleRequestStatuses.ACTIVE : moduleRequestStatuses.INACTIVE))
                    .catch(error => {
                        isError = true;
                        const errorMessage = fetchGraphQLErrors(error);
                        dispatch(snackbarError(t(errorMessage)));
                    });

                await dispatch(setModuleRequestStatusAction(organisation.id,
                    moduleKinds.OkAudit, moduleOkAudit ?
                        moduleRequestStatuses.ACTIVE : moduleRequestStatuses.INACTIVE))
                    .catch(error => {
                        isError = true;
                        const errorMessage = fetchGraphQLErrors(error);
                        dispatch(snackbarError(t(errorMessage)));
                    });

                await dispatch(setModuleRequestStatusAction(organisation.id,
                    moduleKinds.OkPublish, moduleOkPublish ?
                        moduleRequestStatuses.ACTIVE : moduleRequestStatuses.INACTIVE))
                    .catch(error => {
                        isError = true;
                        const errorMessage = fetchGraphQLErrors(error);
                        dispatch(snackbarError(t(errorMessage)));
                    });

                if (!isError) {
                    dispatch(snackbarSuccess(t(message.organisationSaved)));
                    dispatch(fetchOrganisationAction(organisation.id))
                        .then(result => {
                            const { data } = result;
                            const { adminOrganisation } = data;
                            dispatch(setOrganisation(adminOrganisation));
                        });
                }

            } catch(error) {
                dispatch(snackbarError(t(error)));
            }
        }
    };

    const onChange_Module = (event) => {

        const { name } = event.currentTarget;

        switch (name) {
            case modules.MODULE_OK_COMPLY:
                setModuleOkComply(!moduleOkComply);
                break;
            case modules.MODULE_OK_AUDIT:
                setModuleOkAudit(!moduleOkAudit);
                break;
            case modules.MODULE_OK_PUBLISH:
                setModuleOkPublish(!moduleOkPublish);
                break;
            default:
        }
    };

    const items = Array.isArray(users) ? users.map((user) =>
        ({ value: user.id, label: formatUsername(user) })
    ) : [];

    return (
        <div className="tab-edit">
            <h3>{t("organisation.edit.label")}</h3>
            <div className="spacer" />
            <div className="tab-content">
                <div className="cols">
                    <div className="col">
                        <InputDate
                            defaultValue={defaultValues.startDate}
                            label={t("billing.startDate")}
                            name={billingFields.START_DATE}
                            inputRef={register({ required: true })}
                            error={errors.startDate}
                        />
                    </div>
                    <div className="col">
                        <InputDate
                            defaultValue={defaultValues.endDate}
                            label={t("billing.endDate")}
                            name={billingFields.END_DATE}
                            inputRef={register({ required: false })}
                            error={errors.endDate}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <InputText
                            name={billingFields.AGREEMENTS}
                            label={t("billing.agreements.label")}
                            inputRef={register({ required: false })}
                            multiline
                            error={errors.agreements} />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <FormControlLabel
                            control={
                                <Switch
                                    name={modules.MODULE_OK_COMPLY}
                                    onChange={onChange_Module}
                                    checked={moduleOkComply} />}
                            label={modules.MODULE_OK_COMPLY} />
                    </div>
                    <div className="col">
                        <CurrencyTextField
                            name={billingFields.MONTHLY_AMOUNT_OK_COMPLY}
                            label={t("billing.monthlyAmount.okcomply.label")}
                            variant="standard"
                            value={monthlyAmountOkComply}
                            currencySymbol="€"
                            minimumValue="0"
                            outputFormat="number"
                            error={errors.monthlyAmountOkComply}
                            decimalCharacter="."
                            decimalPlaces={2}
                            digitGroupSeparator=","
                            onChange={(event, value)=>
                                setValue(billingFields.MONTHLY_AMOUNT_OK_COMPLY, value)}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <FormControlLabel
                            control={
                                <Switch
                                    name={modules.MODULE_OK_AUDIT}
                                    onChange={onChange_Module}
                                    checked={moduleOkAudit}
                                />}
                            label={modules.MODULE_OK_AUDIT} />
                    </div>
                    <div className="col">
                        <CurrencyTextField
                            name={billingFields.MONTHLY_AMOUNT_OK_AUDIT}
                            label={t("billing.monthlyAmount.audit.label")}
                            variant="standard"
                            value={monthlyAmountOkAudit}
                            currencySymbol="€"
                            minimumValue="0"
                            outputFormat="number"
                            error={errors.monthlyAmountOkAudit}
                            decimalCharacter="."
                            decimalPlaces={2}
                            digitGroupSeparator=","
                            onChange={(event, value)=>
                                setValue(billingFields.MONTHLY_AMOUNT_OK_AUDIT, value)}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <FormControlLabel
                            control={
                                <Switch
                                    name={modules.MODULE_OK_PUBLISH}
                                    onChange={onChange_Module}
                                    checked={moduleOkPublish}
                                />}
                            label={modules.MODULE_OK_PUBLISH} />
                    </div>
                    <div className="col">
                        <CurrencyTextField
                            name={billingFields.MONTHLY_AMOUNT_OK_PUBLISH}
                            label={t("billing.monthlyAmount.publish.label")}
                            variant="standard"
                            value={monthlyAmountOkPublish}
                            currencySymbol="€"
                            minimumValue="0"
                            outputFormat="number"
                            error={errors.monthlyAmountOkPublish}
                            decimalCharacter="."
                            decimalPlaces={2}
                            digitGroupSeparator=","
                            onChange={(event, value)=>
                                setValue(billingFields.MONTHLY_AMOUNT_OK_PUBLISH, value)}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <Controller
                            as={
                                <InputSelect
                                    items={items}
                                    label={t("billing.invoiceTo.label")}
                                    name="region"
                                    onChange={onChangeInvoiceTo}
                                    error={errors.invoiceTo}
                                />}
                            name="invoiceTo"
                            rules={{ required: true }}
                            control={control}
                            defaultValue={invoiceTo || ""}
                        />
                    </div>
                </div>
                <div className="cols">
                    <div className="col">
                        <Button disabled={!calculateRemovalDate(getValues("endDate"))}
                            spacedLeft
                            danger
                            label={t("button.delete.label")}
                            iconLeft={ICONS.TRASH}
                            onClick={onClick_Remove} />
                    </div>
                    <div className="container-buttons col">
                        <Button label={t("button.cancel.label")}
                            spacedLeft
                            outlined
                            onClick={onClose} />
                        <Button label={t("button.save.label")}
                            spacedLeft
                            onClick={handleSubmit(onSubmit)} />
                    </div>
                </div>
            </div>
            {showWarningMessage && 
                <OkWarning onConfirm={onConfirm}
                    onCancel={onCancel}
                    message={t("organisation.remove.message")}
                    title={t("organisation.remove.title")} />}
        </div>);
}


TabEdit.propTypes = {
    organisation: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};