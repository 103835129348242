import { moduleKinds, moduleRequestStatuses } from "constants/module";
import formattedDate from "shared/helpers/date";
import { isValidStringDate } from "helpers/formattedDate";

export function getModules(organisation) {

    let moduleOkComply = false;
    let moduleOkAudit = false;
    let moduleOkPublish = false;

    if (organisation?.modules) {
        const { modules } = organisation;
        const { okComply, okPublish, okAudit } = modules;
        moduleOkComply = okComply === moduleRequestStatuses.ACTIVE;
        moduleOkAudit = okAudit === moduleRequestStatuses.ACTIVE;
        moduleOkPublish = okPublish === moduleRequestStatuses.ACTIVE;
    }

    return {
        moduleOkComply,
        moduleOkAudit,
        moduleOkPublish
    };
}

export function getStartDate(billing) {
    let startDate = "";
    if (isValidStringDate(billing?.startDate)) {
        startDate = formattedDate(billing.startDate);
    }
    return startDate;
}

export function getEndDate(billing) {
    let endDate = "";
    if (isValidStringDate(billing?.endDate)) {
        endDate = formattedDate(billing.endDate);
    }
    return endDate;
}

export function getInvoiceTo(billing) {
    return typeof billing?.invoiceTo !== "undefined" ?
        billing.invoiceTo.id:
        null;
}

export function getAgreements(billing) {
    return typeof billing?.agreements === "string" ? billing.agreements : "";
}

export function getMonthlyAmount(billing) {


    let monthlyAmountOkComply = 0;
    let monthlyAmountOkAudit = 0;
    let monthlyAmountOkPublish = 0;

    if (billing) {

        const { monthlyAmount } = billing;

        if (monthlyAmount) {

            if (typeof monthlyAmount[moduleKinds.OkComply] !== "undefined" &&
                monthlyAmount[moduleKinds.OkComply] !== null) {
                monthlyAmountOkComply = monthlyAmount[moduleKinds.OkComply];
            }
            if (typeof monthlyAmount[moduleKinds.OkAudit] !== "undefined" &&
                monthlyAmount[moduleKinds.OkAudit] !== null) {
                monthlyAmountOkAudit = monthlyAmount[moduleKinds.OkAudit];
            }
            if (typeof monthlyAmount[moduleKinds.OkPublish] !== "undefined" &&
                monthlyAmount[moduleKinds.OkPublish] !== null) {
                monthlyAmountOkPublish =  monthlyAmount[moduleKinds.OkPublish];
            }
        }
    }

    return {
        monthlyAmountOkComply,
        monthlyAmountOkPublish,
        monthlyAmountOkAudit
    };
}

export function getDefaultValues(organisation) {

    const modules = getModules(organisation);
    const agreements = getAgreements(organisation?.billing);
    const monthlyAmount = getMonthlyAmount(organisation?.billing);
    const invoiceTo = getInvoiceTo(organisation?.billing);
    const startDate = getStartDate(organisation?.billing);
    const endDate = getEndDate(organisation?.billing);
    const monthlyAmountOkComply = monthlyAmount.monthlyAmountOkComply;
    const monthlyAmountOkPublish = monthlyAmount.monthlyAmountOkPublish;
    const monthlyAmountOkAudit = monthlyAmount.monthlyAmountOkAudit;
    const moduleOkComply = modules.moduleOkComply;
    const moduleOkPublish = modules.moduleOkPublish;
    const moduleOkAudit = modules.moduleOkAudit;

    return {
        agreements,
        monthlyAmountOkComply,
        monthlyAmountOkPublish,
        monthlyAmountOkAudit,
        invoiceTo,
        startDate,
        endDate,
        moduleOkComply,
        moduleOkPublish,
        moduleOkAudit
    };
}