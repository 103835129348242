import React from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { useParams } from "react-router-dom";

import "./style.scss";

const Modal = ({ title, tabs, basePath, onClose }) => {

    const { tab } = useParams();

    const renderTabContent = () => {
        if (Array.isArray(tabs)) {
            if (!tab && tabs.length === 1) {
                return tabs[0].compontent;
            } else {
                return tabs.map((tabItem) => {
                    const { component, tabName } = tabItem;
                    return tabName === tab ? component : false;
                });
            }
        }
    };

    const renderTabs = () => {
        return Array.isArray(tabs) ?
            tabs.map(({ tabName }, i) => {
                return (
                    <Tab
                        basePath={basePath}
                        tabName={tabName}
                        key={i}
                    />
                );
            }): [];
    };

    return (
        <div className="modal-container">
            <div onClick={onClose} className="modal-background" />
            <div className="tabbed-modal">
                <header className="modal-head">
                    <h3 className="modal-title">{title}</h3>
                    <button
                        className="delete is-large"
                        aria-label="close"
                        onClick={onClose}
                    />
                </header>
                {Array.isArray(tabs) && tabs.length > 1 &&
                    <div className="okcomply-tabs">
                        {renderTabs()}
                    </div>
                }
                <section className="modal-body">
                    {renderTabContent()}
                </section>
            </div>
        </div>
    );
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultTab: PropTypes.string,
    basePath: PropTypes.string,
};

export default Modal;