import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiSwitch from "@material-ui/core/Switch";

import "./style.scss";

const MuiCustomSwitch = withStyles({
    switchBase: {
        color: "white",
        opacity: 0.8,
        "&$checked": {
            color: "#33c2e9",
            opacity: 1
        },
        "&$checked + $track": {
            backgroundColor: "white",
            opacity: 1
        },
        "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: "gray"
        }
    },
    checked: {},
    track: {}
})(MuiSwitch);

export default function Switch(props) {
    return (
        <div className="input-switch">
        {<MuiCustomSwitch { ...props } />}
    </div>);
};
