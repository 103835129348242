export function sortByOrderType(types, values, orderType, direction) {

    let type = types[orderType].field;

    const sortFunction = (a,b) => {
        if (a[type] < b[type]) {
            return -1;
        }
        if (a[type] > b[type]) {
            return 1;
        }
        return 0;
    };

    const sortedValues = values.concat().sort(sortFunction);

    return direction ? sortedValues : sortedValues.reverse();
}