import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import formatUsername from "helpers/formatUsername";
import { moduleKinds, moduleRequestStatuses } from "constants/module";
import { useTranslation } from "react-i18next";
import { modules } from "constants/module";

import "./style.scss";
import Button from "shared/components/buttons/Button";
import removeFromOrganisationAction from "redux/actions/removeFromOrganisationAction";
import fetchGraphQLErrors from "helpers/fetchGraphqlErrors";
import { snackbarError, snackbarSuccess } from "shared/redux/actions/Snackbar";
import { useDispatch } from "react-redux";
import { organisationUpdateOrganisation } from "redux/actions";

export default function OrganisationItem({ item }) {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const renderOwner = () => {
        if (item.owner) {
            return (
                <div className="organisation-list-item-column">
                    <div className="item is-row">
                        <div className="is-column">
                            <span>{formatUsername(item.owner)}</span>
                            <p>{item.owner.email}</p>
                        </div>
                    </div>
                </div>);
        }
        return "";
    };

    const renderModuleImages = () => {

        const mappedModules = [];

        if (item?.modules) {

            if (typeof item.modules[moduleKinds.OkComply] !== "undefined" &&
                item.modules[moduleKinds.OkComply] === moduleRequestStatuses.ACTIVE) {
                mappedModules.push(modules.OK_COMPLY);
            }
            if (typeof item.modules[moduleKinds.OkAudit] !== "undefined" &&
                item.modules[moduleKinds.OkAudit] === moduleRequestStatuses.ACTIVE) {
                mappedModules.push(modules.OK_AUDIT);
            }
            if (typeof item.modules[moduleKinds.OkPublish] !== "undefined" &&
                item.modules[moduleKinds.OkPublish] === moduleRequestStatuses.ACTIVE) {
                mappedModules.push(modules.OK_PUB);
            }
        }

        return mappedModules.map((module, index) =>
            <span key={index}>{getImageForModule(module)}</span>);
    };

    const getImageForModule = (module) => {

        let image = null;
        let moduleName = "";

        switch(module) {
            case modules.OK_COMPLY:
                image = require("shared/img/module-administratie.webp");
                moduleName = modules.OK_COMPLY;
                break;
            case modules.OK_AUDIT:
                image = require("shared/img/module-audit.svg");
                moduleName = modules.OK_AUDIT;
                break;
            case modules.OK_PUB:
                image = require("shared/img/module-bibliotheek.svg");
                moduleName = modules.OK_PUB;
                break;
            default:
        }
        return (
            <span className="module">
                <img alt={moduleName}
                    title={moduleName}
                    className="module-image"
                    src={image} />
            </span>);
    };

    const renderModules = () => {
        return (
            <div className="organisation-list-item-column">
                {renderModuleImages()}
            </div>);
    };

    const removeFromOrganisation = async () => {

        try {
            const result = await dispatch(removeFromOrganisationAction(item.id));

            if (result) {
                const { data } = result;
                const { removeFromOrganisation } = data;
                dispatch(organisationUpdateOrganisation(removeFromOrganisation));
                dispatch(snackbarSuccess("success"));
            }
        } catch(error) {
            const errorMessage = fetchGraphQLErrors(error);
            dispatch(snackbarError(errorMessage));
        }
    };


    const renderButton = () => {
        const { shadow } = item;
        return shadow &&
            <Button
                label={t("organisation.remove.me")}
                secondary
                onClick={removeFromOrganisation} />;
    };

    return (
        <Link to={`/organisations/organisation/${item.id}/info`}>
            <div className="organisation-list-item">
                <div className="organisation-list-item-rows">
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            <div className="item is-bold is-center">
                                {item.label}
                            </div>
                        </div>
                    </div>
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            <div className="label">
                                {t("organisation.owner.singular")}
                            </div>
                            <div className="item">
                                {renderOwner()}
                            </div>
                        </div>
                    </div>
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            <div className="label">
                                {t("organisation.app.plural")}
                            </div>
                            <div className="item">
                                {renderModules()}
                            </div>
                        </div>
                    </div>
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            <div className="label">
                                {t("organisation.branch.plural")}
                            </div>
                            <div className="item">
                                {item.numberOfBranches}
                            </div>
                        </div>
                    </div>
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            <div className="label">
                                {t("organisation.user.plural")}
                            </div>
                            <div className="item">
                                {item.numberOfUsers}
                            </div>
                        </div>
                    </div>
                    <div className="organisation-list-item-row">
                        <div className="organisation-list-item-column">
                            {renderButton()}
                        </div>
                    </div>
                </div>
            </div>
        </Link>);

}

OrganisationItem.propTypes = {
    item: PropTypes.object.isRequired
};

