import client from "../../graphql/client";
import { loader } from "graphql.macro";
const removeOrganisationMutation  = loader("../../graphql/mutations/removeOrganisation.graphql");

export default function removeOrganisationAction(organisationId) {
    return () => {
        return client.mutate({
            mutation: removeOrganisationMutation,
            variables: { organisationId }
        });
    };
}
