export const moduleKinds = {
    OkPublish: "okPublish",
    OkAudit: "okAudit",
    OkComply: "okComply",
    OkOrganisation: "okOrganisation"
};

export const moduleRequestStatuses =  {
    INACTIVE: "INACTIVE", // module is inactive
    PENDING: "PENDING", // e-mail sent request waiting to be processed
    ACTIVE: "ACTIVE", // request approved, module is active
    CANCELED: "CANCELED"
};

export const ModuleRequestStatus =
    moduleRequestStatuses.INACTIVE |
    moduleRequestStatuses.PENDING |
    moduleRequestStatuses.ACTIVE |
    moduleRequestStatuses.CANCELED;

export const moduleRequestType = {
    REQUEST: "REQUEST",
    CANCEL: "CANCEL"
};

export const modules = {
    OK_COMPLY: "GRC",
    OK_AUDIT: "Audit",
    OK_PUB: "Publish"
};

