import client from "../../graphql/client";
import { loader } from "graphql.macro";
const fetchOrganisationsQuery = loader("../../graphql/queries/adminOrganisations.graphql");
const addToOrganisationMutation  = loader("../../graphql/mutations/addToOrganisation.graphql");

export default function addToOrganisationAction(organisationId) {
    return () => {

        const refetchQueries = [{
            query: fetchOrganisationsQuery,
            variables: { organisationId }
        }];

        return client.mutate({
            mutation: addToOrganisationMutation,
            variables: { organisationId },
            refetchQueries
        });
    };
}
