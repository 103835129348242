import client from "../../graphql/client";
import { loader } from "graphql.macro";
const fetchUsersQuery = loader("../../graphql/queries/fetchUsers.graphql");

export default function fetchUsersAction(organisationId) {
    return () => {
        return client.query({
            query: fetchUsersQuery,
            variables: { organisationId },
            fetchPolicy: "network-only"
        });
    };
}
