export const modules = {
    MODULE_OK_COMPLY: "GRC",
    MODULE_OK_AUDIT: "Audit",
    MODULE_OK_PUBLISH: "Publish"
};

export const billingFields = {
    START_DATE: "startDate",
    END_DATE: "endDate",
    AGREEMENTS: "agreements",
    MONTHLY_AMOUNT: "monthlyAmount",
    MONTHLY_AMOUNT_OK_COMPLY: "monthlyAmountOkComply",
    MONTHLY_AMOUNT_OK_AUDIT: "monthlyAmountOkAudit",
    MONTHLY_AMOUNT_OK_PUBLISH: "monthlyAmountOkPublish",
    INVOICE_TO: "invoiceTo"
};