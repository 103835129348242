const formatUsername = function(user) {

    const { id, email, name, surname } = user;

    if(typeof name === "string" && name.length > 0 &&
        typeof surname === "string" && surname.length > 0) {
        return name.concat(" ").concat(surname);
    }
    if(typeof name === "string" && name.length > 0) {
        return name;
    }
    if(typeof email === "string" && email.length > 0) {
        return email;
    }
    return id;
};

export default formatUsername;