import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import organisationsFilter from "redux/actions/organisationsFilter";
import InputText from "shared/components/inputs/InputText";
import InputSelect from "shared/components/inputs/InputSelect";
import Icon, { ICONS } from "shared/components/Icon";

import "./style.scss";

export const Modules = {
    OK_COMPLY: "okComply",
    OK_AUDIT: "okAudit",
    OK_PUBLISH: "okPublish"
};

export default function FilterOrganisations({ onClose }) {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { filters } = useSelector(state => state.organisation);

    const [timeoutRef, setTimeoutRef] = useState();

    const [currentFilters, setCurrentFilters] = useState(filters);

    const moduleOptions = [
        { value: Modules.OK_COMPLY, label: t("filter.module.okComply") },
        { value: Modules.OK_PUBLISH, label: t("filter.module.okPublish") },
        { value: Modules.OK_AUDIT, label: t("filter.module.okAudit") }];

    useEffect(() => {
        clearTimeout(timeoutRef);
        const ref = setTimeout(() => {
            dispatch(organisationsFilter(currentFilters));
        }, 500);
        setTimeoutRef(ref);
    }, [currentFilters, setTimeoutRef, dispatch]);

    const onChange = async ({ target }) => {
        const { name, value } = target;
        setCurrentFilters({ ...currentFilters, [name]: value });
    };

    const onReset = () => {
        setCurrentFilters({});
    };

    return (
        <div className="filter-organisations">
            <header className="filter-head">
                <h3 className="light">
                    {t("organisation.filter.title")}
                </h3>
                <button
                    className="delete is-large"
                    aria-label="close"
                    onClick={onClose}
                />
            </header>
            <div className="spacer" />
            <div className="cols">
                <div className="col">
                    <InputText
                        label={t("organisation.filter.label")}
                        name="label"
                        value={currentFilters?.label || ""}
                        onChange={onChange}
                        dark
                    />
                </div>
                <div className="col">
                    <InputSelect
                        label={t("organisation.filter.module")}
                        name={"modules"}
                        value={currentFilters?.modules || ""}
                        dark
                        items={moduleOptions} onChange={onChange} />
                </div>
            </div>
            <div className="cols">
                <div className="col">
                    <a className="refresh-button" onClick={() => onReset()}>
                        <Icon name={ICONS.REDO} />
                        <span className="has-margin-left">
                            {t("filter.reset")}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

FilterOrganisations.propTypes = {
    onClose: PropTypes.func.isRequired,
};
